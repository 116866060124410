import dayjs from 'dayjs';
import React, { useEffect, useState, useRef } from 'react';
import { Lunar } from 'lunar-typescript';
import "../App.css";
// import songBG from '../songBG.mp3';

const DefaultLayout = () => {
  // const audioRef = useRef(new Audio(songBG)); // Ref to the Audio object

  // Get the current date
  const currentDate = dayjs();

  // Convert the current date to the lunar date
  const lunarDate = Lunar.fromDate(currentDate.toDate());

  // Get the lunar day as a number
  const lunarDayNumber = lunarDate.getDay();

  const currentDayNumber = dayjs().date();

  // Get the current weekday number in the solar calendar
  const currentWeekdayNumber = dayjs().day();

  const [pwd, setpwd] = useState();
  // const [isPlaying, setIsPlaying] = useState(false); // State to manage play/pause

  // const togglePlay = () => {
  //   const audio = audioRef.current;
  //   setIsPlaying(!isPlaying); // Toggle play state

  //   if (!isPlaying) {
  //     audio.play().catch(error => {
  //       console.error('Failed to play audio:', error);
  //     });
  //   } else {
  //     audio.pause();
  //   }
  // };

  useEffect(() => {
    // Calculate password based on conditions
    if (currentWeekdayNumber === 2 || currentWeekdayNumber === 4 || currentWeekdayNumber === 6) {
      setpwd(8338 + lunarDayNumber);
    } else {
      setpwd(8338 + currentDayNumber);
    }
  }, [currentWeekdayNumber, lunarDayNumber, currentDayNumber]);

  return (
    <div className="App">
      <h1 className="h1">Today's password:</h1>
      {pwd}

      {/* Toggle button for play/pause */}
      {/* <div className="toggle-cont">
        <input className="toggle-input" id="toggle" name="toggle" type="checkbox" checked={isPlaying} onChange={togglePlay} />
        <label className="toggle-label" htmlFor="toggle">
          <div className="cont-label-play">
            <span className="label-play"></span>
          </div>
        </label>
      </div> */}
    </div>
  );
};

export default DefaultLayout;
