import React, { useState } from 'react';
import axios from 'axios';


const Login = () => {
    const [base64, setBase64] = useState('');
  const [imageName, setImageName] = useState('');
  const [message, setMessage] = useState('');

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setImageName(file.name);

    const reader = new FileReader();
    reader.onloadend = () => {
      setBase64(reader.result);
    };
    reader.readAsDataURL(file);
  };



    return (
       
        <div className='message-container'>
        <input type="file" accept="image/*" onChange={handleImageUpload} />
        {base64 && (
          <div>
            {/* <h3>Encoded Image:</h3> */}
            {/* <p>{base64}</p> */}
            {/* <h3>Image Name:</h3>
            <p>{imageName}</p> */}
            <img src={base64} alt="upload" />
          </div>
        )}
        <textarea placeholder='Enter your message here' onChange={(event)=>{
            setMessage(event.target.value)
        }} />
        <button onClick={()=>{
            console.log('Message:', message)
            console.log('Image:', base64)
            axios.post('https://nodejsvercel-kappa.vercel.app/message', {
                message: message,
                image: base64
            })
        }}>Send</button>
      </div>

    );
};

export default Login;
