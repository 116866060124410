import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DefaultLayout from './pages/DefaultLayout';
import Login from './pages/Login';
import ReactGA from 'react-ga';
import { Analytics } from "@vercel/analytics/react"


ReactGA.initialize('G-EW3DKV9WLR');


const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/*" element={<DefaultLayout />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </Router>
      <Analytics />
    </div>
  );
};
export default App;